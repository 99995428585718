import '../style/partenaires.css'
import 'reactjs-popup/dist/index.css';
import tela from '../img/grid/Logo_Tela.png'
import anfh from '../img/grid/anfh.png'
import fabre from '../img/grid/museefabre.jpg'
import franceEntreprendre from '../img/grid/logo-entreprendre.png'
import unesco from '../img/grid/logo-unesco.png'
import bioDivesite from '../img/grid/logo-ofb.png'
import draaf from '../img/grid/logo-draaf.png'
import occitanie from '../img/grid/logo-occitanie.png'
import perpignan from '../img/grid/logo-perpignan.png'
import lpo from '../img/grid/logo-LPO.gif'
 

const Clients = () => {
	
	return (
		<div className='Partenaire'>
			<h2>ILS NOUS FONT CONFIANCE</h2>

			<div id='partenaire'>
				<div> <img src={franceEntreprendre}/></div>

				<div> <img src={unesco}/> </div>

				<div> <img src={bioDivesite}/> </div>

				<div> <img src={draaf}/> </div>

				<div> <img src={occitanie}/> </div>

				<div> <img src={perpignan}/> </div>
			
				<div> <img src={tela}/> </div>

				<div> <img src={anfh}/> </div>
				
				<div> <img src={lpo}/> </div>

				<div> <img src={fabre}/> </div>
			</div>
		</div>
	)
}

export default Clients;
