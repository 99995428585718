import '../style/Head.css';
import logo50 from '../img/logo-50cinquante.png'

const Head = () => {
	return(
		<div className='titre'>
			<h2>50% de forme 50% de fond</h2>
			<img src={logo50} id='logo50'/>	
			<h3>
				Des films pour raconter le monde qui nous inspire
			</h3>
		</div>
	)
}

export default Head;
