import {Carousel} from 'react-responsive-carousel'
import '../style/Carousel.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import video from '../img/carousel/video.jpg'
import photo from '../img/carousel/photo.jpg'
import son from '../img/carousel/son.jpg'
import graphisme from '../img/carousel/graphisme.jpg'
import distribution from '../img/carousel/distribution.jpg'
import plateforme from '../img/carousel/plateforme.jpg'
import formation from '../img/carousel/formation.jpg'


const CarouselDiv = () => {

	return(
		<div id='CarouselContainer'>
			<Carousel
				className='Carousel CarouselTitre'
				interval={4500}
				autoPlay={true} 
				showArrows={false}	
				showStatus={false}
				showIndicators={false} 
				showThumbs={false} 
				transitionTime={1000}
				infiniteLoop={true}
				stopOnHover={false}
				dynamicHeight={false}
				>
				<div><h3>VIDÉO</h3></div> 
				<div><h3>PHOTO</h3></div>
				<div><h3>SON</h3></div>
				<div><h3>DISTRIBUTION</h3></div>
				<div><h3>GRAPHISME</h3></div> 
				<div><h3>PLATEFORMES</h3></div> 
				<div><h3>FORMATIONS</h3></div>
			</Carousel> 

			<Carousel
				className='Carousel CarouselImg'
				interval={4500}
				autoPlay={true} 
				showArrows={false}	
				showStatus={false}
				showIndicators={false} 
				showThumbs={false} 
				transitionTime={1000}
				infiniteLoop={true}
				stopOnHover={false}
				dynamicHeight={false}
				>

				<div><img src={video}/></div>
				<div><img src={photo}/></div>
				<div><img src={son}/></div>
				<div><img src={distribution}/></div>
				<div><img src={graphisme}/></div>
				<div><img src={plateforme}/></div>
				<div><img src={formation}/></div>
			</Carousel>
		</div>
		)
}

export default CarouselDiv
