import '../style/Contact.css'

const Contact = () =>{
	return(
		<section id='sectionContact'>
			<div id='ContactContainer'>

					<h1>CONTACT</h1>

			<div id='contact'>

				<div id='formulaire'>

				<iframe src='https://form.jotform.com/221363018631043' scrolling="no" frameBorder="0" width="100%" height="620px"></iframe>
				</div>

				<div id="info">
					<h3>ON PASSE À L'ACTE</h3>
					<p>110 chemin du CARLENCAS </p>
					<p>34980 MONTFERRIER SUR LEZ</p>
					<p>09 83 51 54 76</p>
					<a href='mailto:onpassealacteprod@gmail.com'>onpassealacteprod@gmail.com</a>
					<p>SIRET 840 398 028 000 18</p>
					<p>APE 5911B</p>
				</div>
			</div>

			</div>
		</section>
	)
}

export default Contact;
