import "../style/prestation.css"
import { Link } from "react-router-dom"
import logo50 from '../img/logo-50cinquante.png'
import { useEffect,useState  } from "react"
import Popup from "reactjs-popup"
import ReactPlayer from "react-player"
import book from '../pdf/BOOK.pdf'

const axios = require('axios');

const Prestations = () => {
	const [fiches, setFiches] = useState([]); 
	const path = window.location.pathname;
	const categorie = path.slice(path.length - 10);
	console.log(fiches);

	const dateFormat = (date)=>{
	let	annees = date.substr(0,4)
	let mois = date.substr(5,2)
	let jour = date.substr(8,2)
	let final = jour + "/" + mois + "/" + annees

		return final
	}


	useEffect(()=>{
		window.scrollTo({
			top:0,
			behavior:'instant'
		})

	 axios.get(`https://dev.onpassealacte.fr/back_sas/db.php?categorie=${categorie}`)
		.then(response => setFiches(response.data.fiches))



	},[]);
	


	return(
		<div>
			<nav>
				<Link to='/'>
					<div id='logo_nav'>
						<img src={logo50} id='logo50_nav'/>	
					</div>
				</Link>
			</nav>

			<div className='prestationsTitre'>
				<h2>
					{fiches[0] ? fiches[0].nom_categorie.toUpperCase():" "}	
				</h2>
			</div>

			<div className='prestations'>
				<div className='gridPrestation'>


					{fiches.map((fiche,id)=>
					<Popup  trigger={ 

						<div className='PrestationItem'>
						<ReactPlayer url={fiche.video_courte_1_recette}/> 

						<div className='infoItem'>
								<div className="description_recette" dangerouslySetInnerHTML={{__html:fiche.description_recette}} />

							<div className="prestationInfo">
								<h3>{fiche.nom_recette}</h3>
								<span >Publie le :</span>
								<span>{dateFormat(fiche.date_publication_recette)}</span>
							</div>
						</div>
						</div>
					} modal>
						<ReactPlayer url={fiche.video_courte_1_recette} controls={true} width="100%" height="80vh"/> 
					</Popup>
					)}

				</div>


			</div>

			</div>
	)
}

export default Prestations 
