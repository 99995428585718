import logoEco from '../img/logos/logoEco.png'
import logoMedia from '../img/logos/logoMedia.png'
import logoProd from '../img/logos/logoProd.png'
import '../style/Footer.css'

const Footer = () => {
	return (
		<footer>
			<div id="logoOpa">
				<a href="https://onpassealacte-coop.fr/" target="_blank"><img src={logoEco} /></a>	
				<a href="https://onpassealacte.fr/" target="_blank"><img src={logoMedia} /></a>
			</div>

			<div id='resaux'>
				<a href="https://twitter.com/onpassealacte" target="_blank"><i class="fa fa-twitter-square"></i></a>
				<a href="https://www.facebook.com/onpassealacte" target="_blank"><i class="fa fa-facebook-square"></i></a>
				<a href="https://fr.linkedin.com/in/association-on-passe-%C3%A0-l-acte-b9690362" target="_blank"><i class="fa fa-linkedin-square"></i></a>
			</div>
		</footer>
	)
}

export default Footer
