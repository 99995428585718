import Head from './Head'
import CarouselDiv from './Carousel'
import Cards from './Cards'
import Equipe from './Equipe'
import Contact from './Contact'
import Clients from './Clients'
import { useEffect } from 'react'
import '../App.css'

const Accueil = () =>{

  	useEffect(()=>{
		window.scrollTo({
			top:0,
			behavior:'instant'
		})
	},[]);

return (
    <div>
	  <Head />
		<div className='App'>
	  <CarouselDiv />
	  <Cards />
	  <Clients />
	  <Equipe />
	  <Contact />
		</div>
    </div>
  );
}

export default Accueil;
