import "../style/Equipe.css";
import Mathias from "../img/Equipe/Mathias.jpg";
import Nour from "../img/Equipe/Nour.jpg";
import Raph from "../img/Equipe/Raph.jpg";
import Clara from "../img/Equipe/Clara.jpg";
import Fabien from "../img/Equipe/Fabien.jpg";
import Pauline from "../img/Equipe/Pauline.png";

const Equipe = () => {
	return (
		<div id="equipeComponent">
			<h2>L'ÉQUIPE</h2>
			<div className="Equipe">
				<div className="EquipeBlock">
					<div className="equipeImg">
						<img src={Mathias} />
					</div>

					<h3>Mathias LAHIANI</h3>
					<p>Président et Ingénieur du son</p>
				</div>

				<div className="EquipeBlock">
					<div className="equipeImg">
						<img src={Raph} />
					</div>

					<h3>Raphaël CHASSIGNEUX</h3>
					<p>Conseiller artistique</p>
				</div>

				<div className="EquipeBlock">
					<div className="equipeImg">
						<img src={Fabien} />
					</div>

					<h3>Fabien GAILLARD</h3>
					<p>Vidéaste et Graphiste</p>
				</div>

				<div className="EquipeBlock">
					<div className="equipeImg">
						<img src={Pauline} />
					</div>

					<h3>Pauline ORAIN</h3>
					<p>Vidéaste et Coordinatrice pédagogique et technique</p>
				</div>
			</div>
		</div>
	);
};

export default Equipe;
