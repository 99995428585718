import { Link } from 'react-router-dom';
import logo50 from '../img/logo-50cinquante.png'
import '../style/Demande.css'
import { useEffect, useState } from 'react';

const axios = require('axios');

const Demande = () =>{

	const [categories , setCategories] = useState([]); 


  	useEffect(()=>{
		window.scrollTo({
			top:0,
			behavior:'instant'
		})
	},[]);

	useEffect(()=>{
	 axios.get(`https://dev.onpassealacte.fr/back_sas/db.php`)
		.then(response => setCategories(response.data.categories))
		console.log(categories);
	},[]);

	useEffect(()=>{
	console.log(categories);
},[categories]);

	return(
		<>
			<nav>
				<Link to='/'>
					<div id='logo_nav'>
						<img src={logo50} id='logo50_nav'/>	
					</div>
				</Link>
			</nav>
		<section id='sectionDemande'>

			<h1>CONTACT</h1> 
			<h4>Parlez nous de votre projet, et nous vous recontacterons pour établir un devis</h4>

				<div id='formulaireDemande'>

					<form>
							<div className="inputBlock">
 								<label for="nom">Nom</label>
								<input name="nom" type='text'></input>
							</div>

							<div className="inputBlock">
								<label for="prenom">Prénom</label>
								<input name="prenom" type='text'></input>
							</div>

							<div className="inputBlock">
								<label for="email">Email</label>
								<input name="prenom" type='text'></input>
							</div>

							<div className="inputBlock">
								<label for="telephone">Téléphone</label>
								<input name="telephone" type='text'></input>
							</div>

							<div className="inputBlock">
								<label for="type_projet">Type de projet</label>
								<select name="type_projet">
								{
								categories.map((categorie,id) =>

									<option value={`${categorie.nom_categorie}`}>{`${categorie.nom_categorie}`}</option>
								)}
								
								</select>
							</div>

							<div id="message">
							<label for="text_projet">Parlez nous de votre projet</label>
							<textarea/>
							</div>

							<div className="sendBlock">
								<input type='submit' id='submit' value="Envoyer"></input>
							</div>
					</form>


			</div>

		</section>
		</>
	)
}

export default Demande;
