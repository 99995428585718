import {Link} from 'react-router-dom'
import '../style/Cards.css';
import { useState, useEffect } from 'react';
import book from '../pdf/BOOK.pdf'

const axios = require('axios');

const Cards = () => {
	const [categories , setCategories] = useState([]); 

	useEffect(()=>{
	 axios.get(`https://dev.onpassealacte.fr/back_sas/db.php`)
		.then(response => setCategories(response.data.categories))
		console.log(categories);
	},[]);
	return(
		<div className='Cards'>
			{categories.map((categorie, id) =>

			<Link to={`/prestations/${categorie.id_categorie}`}>
				<div className='card'>

					<img src={`https://dev.onpassealacte.fr/back_sas/img_categorie/${categorie.img_url}`} className='imgCards' />
					<h3>{categorie.nom_categorie.toUpperCase()}</h3> 

					<div className='CardsHover'>
					</div>
				</div>

			</Link>

			)}

			<a href={book} target="_blank">
				<div className='card'>
						<img src={`https://dev.onpassealacte.fr/back_sas/img_categorie/1650028138-1649852837-book.jpg`} className='imgCards' />
						<h3>BOOK</h3> 

						<div className='CardsHover'>
						</div>

				</div>
			</a>

			<a href="https://onpassealacte.fr/formation-production-audiovisuelle.html" target="_blank">
				<div className='card'>
						<img src={`https://dev.onpassealacte.fr/back_sas/img_categorie/1650028179-1649852873-formations.png`} className='imgCards' />
						<h3>FORMATION AU SON ET À L'IMAGE</h3> 

						<div className='CardsHover'>
						</div>

				</div>
			</a>

			<a href="http://www.lelabodececile.fr/" target="_blank">
				<div className='card'>
						<img src={`https://dev.onpassealacte.fr/back_sas/img_categorie/1650028192-1649852885-labodecÉcilestudio.png`} className='imgCards' />
						<h3>LE LABO DE CÉCILE</h3> 

						<div className='CardsHover'>
						</div>

				</div>
			</a>
			
			<a href="https://onpassealacte.fr/boutique.html" target="_blank">
				<div className='card'>
						<img src={`https://dev.onpassealacte.fr/back_sas/img_categorie/1650028206-1649852899-boutique.png`} className='imgCards' />
						<h3>BOUTIQUE</h3> 

						<div className='CardsHover'>
						</div>

				</div>
			</a>
		</div>
	)
}

export default Cards;
