import Accueil from './components/Accueil.js'
import Prestations from './components/Prestations.js'
import Demande from './components/Demande.js'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Footer from './components/Footer.js'
function App() {

  return (
    <div>
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Accueil />}/>
				<Route path='/prestations/:categorie' element={<Prestations />}/>
				<Route path='/demande_prestation' element={<Demande />}/>
			</Routes>
		</BrowserRouter>
		<Footer />
    </div>
  );
}

export default App;
